import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectActivityV3 } from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { getStatusList } from '@sdir/blueprint.et/lib/helpers';
import {
  FocusAreaFilterOptions,
  ScopeFilterOptions,
  TaskStatusFilterOptions,
  TaskTypeFilterOptions
} from '@sdir/blueprint.et/lib/types/enums';
import {
  ActiveFilters,
  filterDropdownTypes,
  defaultActiveFilters
} from '@sdir/blueprint.et/lib/components/Organisms/TaskFilters/TaskFiltersV1';
import { UPDATE_ACTIVITY_STATUS_COMPLETED } from '../../store/constants/action-types';

const useTaskFilter = (
  activities: ProjectActivityV3[] | null | undefined,
  includeNotRelevantActivities: boolean,
  setIncludeNotRelevantActivities: React.Dispatch<React.SetStateAction<boolean>>,
  getActivityList?: () => void,
  resetSelectedActivities?: () => void
) => {
  const dispatch = useDispatch();
  const activityStatusChanged = useSelector((state: any) => state.appState.activityStatusChanged);
  const [activeFilters, setActiveFilters] = useState<ActiveFilters>({
    ...defaultActiveFilters,
    status: [
      TaskStatusFilterOptions.Accepted,
      TaskStatusFilterOptions.AwaitingSdir,
      TaskStatusFilterOptions.AwaitingShippingCompany,
      TaskStatusFilterOptions.Reaction
    ]
  });
  const [filteredActivities, setFilteredActivities] = useState<ProjectActivityV3[]>();

  const availableIntervalsCertificatesAndInspectionTypes: filterDropdownTypes = useMemo(() => {
    const uniqueIntervals = new Set();
    const uniqueInspectionTypes = new Set();
    const uniqueCertificates = new Set();

    if (activities) {
      activities?.forEach(activity => {
        activity.intervals?.forEach(i => uniqueIntervals.add(i.label));
        activity.surveyTypes?.forEach(i => uniqueInspectionTypes.add(i.label));
        activity.certificates?.forEach(i => uniqueCertificates.add(i));
      });
    }
    const intervals = Array.from(uniqueIntervals).sort() as string[];
    const inspectionTypes = Array.from(uniqueInspectionTypes).sort() as string[];
    const certificates = Array.from(uniqueCertificates).sort() as string[];
    return { intervals, inspectionTypes, certificates };
  }, [activities]);

  const availableUnscheduledSurveyTypes: string[] = useMemo(() => {
    const uniqueUnscheduledSurveyTypes = new Set();
    if (activities) {
      activities?.forEach(activity => {
        activity.unscheduledSurveyTypes?.forEach(i => uniqueUnscheduledSurveyTypes.add(i.label));
      });
    }
    return Array.from(uniqueUnscheduledSurveyTypes).sort() as string[];
  }, [activities]);

  useEffect(() => updateFilteredActivities(), [activities, activeFilters]);

  const updateFilteredActivities = () => {
    // To refresh activity list if activity status was changed by Bekreft Oppgave/Klar for inspection
    if (activityStatusChanged?.subjectAreaUri && getActivityList) {
      getActivityList();
      dispatch({ type: UPDATE_ACTIVITY_STATUS_COMPLETED });
    }
    let newFilteredActivities: ProjectActivityV3[] = filterTasksByStatus();
    newFilteredActivities = filterByType(newFilteredActivities);
    newFilteredActivities = filterTasksByCertificate(newFilteredActivities);
    newFilteredActivities = filterTasksByScope(newFilteredActivities);
    newFilteredActivities = filterTasksByFocusArea(newFilteredActivities);
    newFilteredActivities = filterTaskByIntervals(newFilteredActivities);
    newFilteredActivities = filterTaskByInspectionTypes(newFilteredActivities);
    newFilteredActivities = filterTaskByUnscheduledSurveyTypes(newFilteredActivities);
    setFilteredActivities(newFilteredActivities);
  };

  const filterByType = (activitiesByStatus: ProjectActivityV3[]) => {
    if (!activitiesByStatus) return [];
    switch (activeFilters.taskType) {
      case TaskTypeFilterOptions.ShowAll:
        return activitiesByStatus;
      case TaskTypeFilterOptions.DocumentControl:
        return activitiesByStatus.filter(activity =>
          activity.checkpointType?.includes('DocumentControl')
        );
      case TaskTypeFilterOptions.Inspection:
        return activitiesByStatus.filter(activity =>
          activity.checkpointType?.includes('Inspection')
        );
      default:
        return activitiesByStatus;
    }
  };

  const filterTasksByStatus = () => {
    if (!activities) return [];
    if (
      activeFilters.status.length === 0 ||
      activeFilters.status.includes(TaskStatusFilterOptions.ShowAll)
    ) {
      return [...activities];
    }
    const filteredTasks: any[] = [];
    const selectedStatusList = getStatusList(activeFilters.status);
    activities?.forEach(activity => {
      if (
        (activity?.lastEvent?.status && selectedStatusList.includes(activity.lastEvent.status)) ||
        (!activity?.lastEvent &&
          activeFilters.status.includes(TaskStatusFilterOptions.AwaitingShippingCompany))
      ) {
        filteredTasks.push(activity);
      }
    });
    return filteredTasks;
  };

  const filterTasksByCertificate = (projectActivities: ProjectActivityV3[]) => {
    if (!projectActivities) return [];
    if (
      activeFilters?.certificates?.length === 0 ||
      activeFilters.certificates.includes(TaskStatusFilterOptions.ShowAll)
    ) {
      return [...projectActivities];
    }
    const filteredTasks: ProjectActivityV3[] = [];
    projectActivities?.forEach(activity => {
      if (activeFilters.certificates.some(ai => activity.certificates?.includes(ai))) {
        filteredTasks.push(activity);
      }
    });
    return filteredTasks;
  };

  const filterTasksByScope = (projectActivities: ProjectActivityV3[]) => {
    if (!projectActivities) return [];
    if (!activeFilters?.scope || activeFilters.scope === ScopeFilterOptions.ShowAll) {
      return [...projectActivities];
    }
    const filteredTasks: ProjectActivityV3[] = [];
    projectActivities?.forEach(activity => {
      if (
        (activeFilters.scope === ScopeFilterOptions.Mandatory && activity?.scope === true) ||
        (activeFilters.scope === ScopeFilterOptions.Expanded && activity?.scope === false)
      ) {
        filteredTasks.push(activity);
      }
    });
    return filteredTasks;
  };

  const filterTasksByFocusArea = (projectActivities: ProjectActivityV3[]) => {
    if (!projectActivities || activeFilters.focusArea === FocusAreaFilterOptions.No) return [];
    return [...projectActivities];
  };

  const filterTaskByIntervals = (projectActivities: ProjectActivityV3[]) => {
    if (!projectActivities) return [];
    if (activeFilters?.intervals?.length === 0) {
      return [...projectActivities];
    }
    const filteredTasks: ProjectActivityV3[] = [];
    projectActivities?.forEach(activity => {
      if (
        activeFilters.intervals.some(ai =>
          activity.intervals?.some(interval => interval.label === ai)
        )
      ) {
        filteredTasks.push(activity);
      }
    });
    return filteredTasks;
  };

  const filterTaskByInspectionTypes = (projectActivities: ProjectActivityV3[]) => {
    if (!projectActivities) return [];
    if (activeFilters?.inspectionTypes?.length === 0) {
      return [...projectActivities];
    }

    const filteredTasks: ProjectActivityV3[] = [];
    projectActivities?.forEach(activity => {
      if (
        activeFilters.inspectionTypes.some(ai =>
          activity.surveyTypes?.some(sType => sType.label === ai)
        )
      ) {
        filteredTasks.push(activity);
      }
    });
    return filteredTasks;
  };

  const filterTaskByUnscheduledSurveyTypes = filteredActivitiesList => {
    if (!filteredActivitiesList) return [];
    if (
      !activeFilters.unscheduledSurveyTypes ||
      activeFilters.unscheduledSurveyTypes.length === 0
    ) {
      return [...filteredActivitiesList];
    }
    const filteredTasks: ProjectActivityV3[] = [];
    filteredActivitiesList?.forEach(activity => {
      if (
        activeFilters?.unscheduledSurveyTypes?.some(ai =>
          activity?.unscheduledSurveyTypes.some(inspectionType => ai === inspectionType.label)
        )
      ) {
        filteredTasks.push(activity);
      }
    });
    return filteredTasks;
  };

  const onFiltersChanged = (newActiveFilters: ActiveFilters) => {
    if (
      !includeNotRelevantActivities &&
      newActiveFilters.status.includes(TaskStatusFilterOptions.NotRelevant)
    ) {
      setIncludeNotRelevantActivities(true);
    }
    setActiveFilters(newActiveFilters);
    updateFilteredActivities();
  };

  const refreshFiltersAndSelectedActivities = (selectedFilters: ActiveFilters) => {
    resetSelectedActivities && resetSelectedActivities();
    setActiveFilters(selectedFilters);
  };

  const resetStatusAndCertificateFilters = () => {
    setActiveFilters({
      ...activeFilters,
      taskType: defaultActiveFilters.taskType,
      status: defaultActiveFilters.status
    });
  };

  const taskFilterData = {
    filteredActivities,
    activeFilters,
    onFiltersChanged,
    resetStatusAndCertificateFilters,
    refreshFiltersAndSelectedActivities,
    availableIntervalsCertificatesAndInspectionTypes,
    availableUnscheduledSurveyTypes
  };

  return taskFilterData;
};

export default useTaskFilter;
