import { useMemo } from 'react';
import { useToast } from '@sdir/utilities/lib/hooks';
import { useIntl } from 'react-intl';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { SdirCheckbox, SdirDatePicker, SdirRadioButtonGroup } from '@sdir/sds';
import styled from 'styled-components';
import { userPermissionApi } from '../../../httpclient';

export interface PermissionEntity {
  value: string;
  name: string;
}

export enum Permissions {
  ReadAccessToAllSurveys = 'ReadAccessToAllSurveys',
  UpdateAccessToAllSurveys = 'UpdateAccessToAllSurveys',
  ReadAccessToAllConstructionNotices = 'ReadAccessToAllConstructionNotices',
  UpdateAccessToAllConstructionNotices = 'UpdateAccessToAllConstructionNotices',
  ReadAccessToUserPermissions = 'ReadAccessToUserPermissions',
  UpdateAccessToUserPermissions = 'UpdateAccessToUserPermissions',
  ReadAccessToActivity = 'ReadAccessToActivity',
  UpdateAccessToActivity = 'UpdateAccessToActivity'
}

const useUserAdministrationModalUtilitites = (
  selectedPermissions: Permissions[],
  setSelectedPermissions,
  timeframeActivities,
  setTimeframeActivities,
  timeframeFromDate,
  setTimeframeFromDate,
  timeframeToDate,
  setTimeframeToDate,
  getInvitedUsersAndUsersCallback,
  setShowModal
) => {
  const fm = useIntl().formatMessage;
  const { setError, setSuccess } = useToast();
  const readOptionLabel = fm({ id: 'useradmin.modal.read' });
  const writeOptionLabel = fm({ id: 'useradmin.modal.readandwrite' });

  const { callApi: updatePermissions, loading: updatePermissionsLoading } = useApi({
    apiFunction: payload =>
      userPermissionApi.v1UserpermissionUpdateuserpermissionsRequestPut(payload),
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${fm({ id: 'useradmin.modal.update.toast.error' })}: ${err}`);
      }
    },
    successCallback: () => {
      setSuccess(fm({ id: 'useradmin.modal.update.toast.success' }));
      getInvitedUsersAndUsersCallback();
      setShowModal(false);
    }
  });

  const { callApi: sendInvitation, loading: sendInvitationLoading } = useApi({
    apiFunction: payload =>
      userPermissionApi.v1UserpermissionInviteuserInvitationRequestPost(payload),
    errorCallback: err => {
      if (err.length > 0) {
        setError(`${fm({ id: 'useradmin.modal.invite.toast.error' })}: ${err}`);
      }
    },
    successCallback: () => {
      setSuccess(fm({ id: 'useradmin.modal.invite.toast.success' }));
      getInvitedUsersAndUsersCallback();
      setShowModal(false);
    }
  });

  const selectPermission = async (permission: Permissions) => {
    const copyOfSelectedPermissionsWithNewValue = [...selectedPermissions, permission];

    // Remove the Read/Write counterpart permission
    switch (permission) {
      case Permissions.ReadAccessToAllConstructionNotices:
        await setSelectedPermissions(
          copyOfSelectedPermissionsWithNewValue.filter(
            p => p !== Permissions.UpdateAccessToAllConstructionNotices
          )
        );
        break;
      case Permissions.UpdateAccessToAllConstructionNotices:
        await setSelectedPermissions(
          copyOfSelectedPermissionsWithNewValue.filter(
            p => p !== Permissions.ReadAccessToAllConstructionNotices
          )
        );
        break;
      case Permissions.ReadAccessToUserPermissions:
        await setSelectedPermissions(
          copyOfSelectedPermissionsWithNewValue.filter(
            p => p !== Permissions.UpdateAccessToUserPermissions
          )
        );
        break;
      case Permissions.UpdateAccessToUserPermissions:
        await setSelectedPermissions(
          copyOfSelectedPermissionsWithNewValue.filter(
            p => p !== Permissions.ReadAccessToUserPermissions
          )
        );
        break;
      case Permissions.ReadAccessToActivity:
        await setSelectedPermissions(
          copyOfSelectedPermissionsWithNewValue.filter(
            p => p !== Permissions.UpdateAccessToActivity
          )
        );
        break;
      case Permissions.UpdateAccessToActivity:
        await setSelectedPermissions(
          copyOfSelectedPermissionsWithNewValue.filter(p => p !== Permissions.ReadAccessToActivity)
        );
        break;
      case Permissions.ReadAccessToAllSurveys:
        await setSelectedPermissions(
          copyOfSelectedPermissionsWithNewValue.filter(
            p => p !== Permissions.UpdateAccessToAllSurveys
          )
        );
        break;
      case Permissions.UpdateAccessToAllSurveys:
        await setSelectedPermissions(
          copyOfSelectedPermissionsWithNewValue.filter(
            p => p !== Permissions.ReadAccessToAllSurveys
          )
        );
        break;
      default:
        break;
    }
  };

  const activitiesChecked = useMemo(
    () =>
      selectedPermissions.some(sp =>
        [Permissions.UpdateAccessToActivity, Permissions.ReadAccessToActivity].includes(sp)
      ),
    [selectedPermissions]
  );

  const userPermissionsChecked = useMemo(
    () =>
      selectedPermissions.some(sp =>
        [
          Permissions.UpdateAccessToUserPermissions,
          Permissions.ReadAccessToUserPermissions
        ].includes(sp)
      ),
    [selectedPermissions]
  );

  const constructionNoticeChecked = useMemo(
    () =>
      selectedPermissions.some(sp =>
        [
          Permissions.UpdateAccessToAllConstructionNotices,
          Permissions.ReadAccessToAllConstructionNotices
        ].includes(sp)
      ),
    [selectedPermissions]
  );

  const surveyChecked = useMemo(
    () =>
      selectedPermissions.some(sp =>
        [Permissions.UpdateAccessToAllSurveys, Permissions.ReadAccessToAllSurveys].includes(sp)
      ),
    [selectedPermissions]
  );

  const PermissionSelector = () => {
    return (
      <>
        <Subheader>{fm({ id: 'useradmin.modal.invite.access.header' })}</Subheader>
        <CheckboxWrapper>
          {/* <SdirCheckbox
              title={fm({ id: 'useradmin.modal.invite.createsurvey' })}
              onClick={() => setCreatesurveyChecked(!createsurveyChecked)}
              checked={createsurveyChecked}
            />
            {createsurveyChecked && (
              <IndentWrapper>
                <SdirRadioButtonGroup
                  as={CustomContainer}
                  options={[writeOption]}
                  onChange={e => setCreatesurveyAcces(e.target.value as ReadWriteAccess.WRITE)}
                  value={createsurveyAcces ?? ReadWriteAccess.WRITE}
                  name=""
                />
              </IndentWrapper>
            )} */}
          <SdirCheckbox
            title={fm({ id: 'useradmin.modal.invite.constructionnotice' })}
            onClick={() => {
              if (constructionNoticeChecked) {
                setSelectedPermissions(
                  selectedPermissions.filter(
                    sp =>
                      ![
                        Permissions.ReadAccessToAllConstructionNotices,
                        Permissions.UpdateAccessToAllConstructionNotices
                      ].includes(sp)
                  )
                );
              } else {
                selectPermission(Permissions.ReadAccessToAllConstructionNotices);
              }
            }}
            checked={constructionNoticeChecked}
          />
          {constructionNoticeChecked && (
            <IndentWrapper>
              <SdirRadioButtonGroup
                as={CustomContainer}
                options={[
                  {
                    label: writeOptionLabel,
                    value: Permissions.UpdateAccessToAllConstructionNotices
                  },
                  {
                    label: readOptionLabel,
                    value: Permissions.ReadAccessToAllConstructionNotices
                  }
                ]}
                onChange={e => selectPermission(e.target.value as Permissions)}
                value={
                  selectedPermissions.includes(Permissions.UpdateAccessToAllConstructionNotices)
                    ? Permissions.UpdateAccessToAllConstructionNotices
                    : Permissions.ReadAccessToAllConstructionNotices
                }
                name=""
              />
            </IndentWrapper>
          )}
          <SdirCheckbox
            title={fm({ id: 'useradmin.modal.invite.activities' })}
            onClick={() => {
              if (activitiesChecked) {
                setSelectedPermissions(
                  selectedPermissions.filter(
                    sp =>
                      ![
                        Permissions.ReadAccessToActivity,
                        Permissions.UpdateAccessToActivity
                      ].includes(sp)
                  )
                );
              } else {
                selectPermission(Permissions.ReadAccessToActivity);
              }
            }}
            checked={activitiesChecked}
          />
          {activitiesChecked && (
            <>
              <IndentWrapper>
                <SdirRadioButtonGroup
                  as={CustomContainer}
                  options={[
                    {
                      label: writeOptionLabel,
                      value: Permissions.UpdateAccessToActivity
                    },
                    {
                      label: readOptionLabel,
                      value: Permissions.ReadAccessToActivity
                    }
                  ]}
                  onChange={e => selectPermission(e.target.value as Permissions)}
                  value={
                    selectedPermissions.includes(Permissions.UpdateAccessToActivity)
                      ? Permissions.UpdateAccessToActivity
                      : Permissions.ReadAccessToActivity
                  }
                  name=""
                />
              </IndentWrapper>
              <IndentWrapper>
                {/* <SdirRadioButtonGroup
                    as={CustomContainer}
                    options={[
                      {
                        value: ActivitiesSubjectareas.ALL,
                        label: fm({ id: 'useradmin.modal.subjectarea.all' })
                      },
                      {
                        value: ActivitiesSubjectareas.LIMITED,
                        label: fm({ id: 'useradmin.modal.subjectarea.limited' })
                      }
                    ]}
                    onChange={e =>
                      setActivitiesSubjectareas(e.target.value as ActivitiesSubjectareas)
                    }
                    value={activitiesSubjectareas ?? ActivitiesSubjectareas.ALL}
                    name=""
                  />
                  {activitiesSubjectareas === ActivitiesSubjectareas.LIMITED && (
                    <SelectWrapper>
                      <MultiSelect
                        defaultVal={fm({
                          id: 'useradmin.modal.subjectarea.placeholder'
                        })}
                        handleChange={subjectarea => {
                          const alreadyExists =
                            limitedSubjectareas.findIndex(el => el === subjectarea) >= 0;
                          if (alreadyExists) {
                            const newState = limitedSubjectareas.filter(
                              el => el !== subjectarea
                            );
                            setLimitedSubjectareas(newState);
                          } else {
                            setLimitedSubjectareas([...limitedSubjectareas, subjectarea]);
                          }
                        }}
                        options={(checkpointOptions?.subjectAreasWithSubnodes ?? []).map(
                          subj => ({
                            value: subj.subjectAreaValue ?? '',
                            name: subj.subjectAreaLabel ?? ''
                          })
                        )}
                        value={limitedSubjectareas}
                      />
                    </SelectWrapper>
                  )} */}
                <TimeFrameWrapper>
                  <SdirCheckbox
                    title={fm({ id: 'useradmin.modal.subjectarea.timeframe' })}
                    onClick={() => setTimeframeActivities(!timeframeActivities)}
                    checked={timeframeActivities}
                  />
                  {timeframeActivities && (
                    <DatepickerWrapper>
                      <SdirDatePicker
                        placeholder={fm({
                          id: 'useradmin.modal.subjectarea.timeframe.placeholder'
                        })}
                        dateFormat="dd.MM.yyyy"
                        onChange={v => setTimeframeFromDate(v)}
                        value={timeframeFromDate}
                      />
                      <SdirDatePicker
                        placeholder={fm({
                          id: 'useradmin.modal.subjectarea.timeframe.placeholder'
                        })}
                        dateFormat="dd.MM.yyyy"
                        onChange={v => setTimeframeToDate(v)}
                        value={timeframeToDate}
                      />
                    </DatepickerWrapper>
                  )}
                </TimeFrameWrapper>
              </IndentWrapper>
            </>
          )}
          <SdirCheckbox
            title={fm({ id: 'useradmin.modal.invite.useradmin' })}
            onClick={() => {
              if (userPermissionsChecked) {
                setSelectedPermissions(
                  selectedPermissions.filter(
                    sp =>
                      ![
                        Permissions.ReadAccessToUserPermissions,
                        Permissions.UpdateAccessToUserPermissions
                      ].includes(sp)
                  )
                );
              } else {
                selectPermission(Permissions.ReadAccessToUserPermissions);
              }
            }}
            checked={userPermissionsChecked}
          />
          {userPermissionsChecked && (
            <IndentWrapper>
              <SdirRadioButtonGroup
                as={CustomContainer}
                options={[
                  {
                    label: writeOptionLabel,
                    value: Permissions.UpdateAccessToUserPermissions
                  },
                  {
                    label: readOptionLabel,
                    value: Permissions.ReadAccessToUserPermissions
                  }
                ]}
                onChange={e => selectPermission(e.target.value as Permissions)}
                value={
                  selectedPermissions.includes(Permissions.UpdateAccessToUserPermissions)
                    ? Permissions.UpdateAccessToUserPermissions
                    : Permissions.ReadAccessToUserPermissions
                }
                name=""
              />
            </IndentWrapper>
          )}
          {/* <SdirCheckbox
              title={fm({ id: 'useradmin.modal.invite.accidents' })}
              onClick={() => setAccidentsChecked(!accidentsChecked)}
              checked={accidentsChecked}
              checked={accidentsChecked}
            />
            {accidentsChecked && (
              <IndentWrapper>
                <SdirRadioButtonGroup
                  as={CustomContainer}
                  options={[writeOption, readOption]}
                  onChange={e => setAccidentsAcces(e.target.value as ReadWriteAccess)}
                  value={accidentsAcces ?? ReadWriteAccess.READ}
                  name=""
                />
              </IndentWrapper>
            )} */}
          <SdirCheckbox
            title={fm({ id: 'useradmin.modal.invite.inspection' })}
            onClick={() => {
              if (surveyChecked) {
                setSelectedPermissions(
                  selectedPermissions.filter(
                    sp =>
                      ![
                        Permissions.ReadAccessToAllSurveys,
                        Permissions.UpdateAccessToAllSurveys
                      ].includes(sp)
                  )
                );
              } else {
                selectPermission(Permissions.ReadAccessToAllSurveys);
              }
            }}
            checked={surveyChecked}
          />
          {surveyChecked && (
            <IndentWrapper>
              <SdirRadioButtonGroup
                as={CustomContainer}
                options={[
                  {
                    label: writeOptionLabel,
                    value: Permissions.UpdateAccessToAllSurveys
                  },
                  {
                    label: readOptionLabel,
                    value: Permissions.ReadAccessToAllSurveys
                  }
                ]}
                onChange={e => selectPermission(e.target.value as Permissions)}
                value={
                  selectedPermissions.includes(Permissions.UpdateAccessToAllSurveys)
                    ? Permissions.UpdateAccessToAllSurveys
                    : Permissions.ReadAccessToAllSurveys
                }
                name=""
              />
            </IndentWrapper>
          )}
        </CheckboxWrapper>
      </>
    );
  };

  return {
    fm,
    updatePermissions,
    updatePermissionsLoading,
    sendInvitation,
    sendInvitationLoading,
    PermissionSelector
  };
};

export default useUserAdministrationModalUtilitites;

export const Subheader = styled.h3`
  margin-top: 0;
`;

const IndentWrapper = styled.div`
  padding-left: 3rem;
`;

const TimeFrameWrapper = styled.div`
  margin-top: 2rem;
`;
const DatepickerWrapper = styled.div`
  display: flex;
  gap: 2rem;
  margin-top: 2rem;

  & > div {
    width: 20rem;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-bottom: 0.5rem;
`;

const CustomContainer = styled.div`
  display: flex;
  flex-direction: column;

  [data-id='base-item'] {
    padding-bottom: 1.5rem;
  }
`;
