import React from 'react';
import { Info, InfoLabel } from '../Atoms';
import { InfoLabelInterface } from '../Atoms/InfoLabel';
import { InfoInterface } from '../Atoms/Info';

const InfoField: React.FC<InfoLabelInterface & InfoInterface> = ({ label, text }) => {
  return (
    <>
      <InfoLabel label={label} />
      <Info text={text} />
    </>
  );
};

export default InfoField;
