import { OptionDto } from '@sdir/httpclient/lib/clients/et/survey';
import { SET_SELECTED_CERTIFICATE_DATA } from '../constants/action-types';
import { surveyState } from '../constants/types';

const initialSurveyState: surveyState = {
  selectedSurveyCertificateData: {
    $type: '',
    surveyType: {},
    checkpointType: undefined,
    certificateType: undefined,
    inspectionTypes: undefined,
    surveyId: undefined,
    interval: undefined
  }
};

export default function surveyReducer(state = initialSurveyState, action) {
  switch (action.type) {
    case SET_SELECTED_CERTIFICATE_DATA:
      return {
        ...state,
        selectedSurveyCertificateData: action.payload
      };
    default:
      return state;
  }
}
