import React, { useState, useEffect } from 'react';
import { SdirButton, SdirModal } from '@sdir/sds';
import styled from 'styled-components';
import { GetCheckpointOptionsResultV2 } from '@sdir/httpclient/lib/clients/core/requirement';
import { ManagedUser } from '@sdir/httpclient/lib/clients/core/authorization';
import { ErrorNotice } from '@sdir/blueprint.et/lib/components';
import useUserAdministrationModalUtilitites, {
  PermissionEntity,
  Permissions,
  Subheader
} from './useUserAdministrationModalUtilties';
import { Footer, RightSection, LeftSection, Header, Content } from './UserAdministrationAddModal';

interface Props {
  checkpointOptions?: GetCheckpointOptionsResultV2 | undefined;
  setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  getInvitedUsersAndUsers: () => void;
  selectedEntity?: PermissionEntity;
  selectedUser?: ManagedUser;
}

const UserAdministrationEditModal: React.FC<Props> = ({
  selectedUser,
  selectedEntity,
  setShowEditModal,
  getInvitedUsersAndUsers
}) => {
  const [selectedPermissions, setSelectedPermissions] = useState<Permissions[]>([]);
  const [timeframeActivities, setTimeframeActivities] = useState(false);
  const [timeframeFromDate, setTimeframeFromDate] = useState<Date | null | undefined>();
  const [timeframeToDate, setTimeframeToDate] = useState<Date | null | undefined>();
  const [saveButtonPressed, setSaveButtonPressed] = useState(false);

  const {
    fm,
    updatePermissions,
    updatePermissionsLoading,
    PermissionSelector
  } = useUserAdministrationModalUtilitites(
    selectedPermissions,
    setSelectedPermissions,
    timeframeActivities,
    setTimeframeActivities,
    timeframeFromDate,
    setTimeframeFromDate,
    timeframeToDate,
    setTimeframeToDate,
    getInvitedUsersAndUsers,
    setShowEditModal
  );

  useEffect(() => {
    if (selectedUser && selectedEntity) {
      const permissionsForCurrentEntitiy = (selectedUser.entities || []).find(
        e => e.uid === selectedEntity.value
      );

      if (permissionsForCurrentEntitiy?.permissions) {
        setSelectedPermissions(
          (permissionsForCurrentEntitiy.permissions.map(p => p.key) as Permissions[]) || []
        );
      }

      // Set validfrom and validto date for activitypermissions
      const activityPermission = permissionsForCurrentEntitiy?.permissions?.find(
        p =>
          p.key === Permissions.ReadAccessToActivity || p.key === Permissions.UpdateAccessToActivity
      );

      if (activityPermission) {
        if (activityPermission.validFrom) {
          setTimeframeActivities(true);
          setTimeframeFromDate(new Date(activityPermission.validFrom));
        }

        if (activityPermission.validTo) {
          setTimeframeActivities(true);
          setTimeframeToDate(new Date(activityPermission.validTo));
        }
      }
    }
  }, [selectedUser, selectedEntity]);

  const handleUpdatePermissions = () => {
    setSaveButtonPressed(true);
    if (selectedEntity?.value && selectedUser?.email && selectedPermissions.length) {
      const payload = {
        email: selectedUser?.email,
        entityIds: [selectedEntity.value],
        permissions: selectedPermissions.map(sp => {
          if (
            sp === Permissions.ReadAccessToActivity ||
            sp === Permissions.UpdateAccessToActivity
          ) {
            return {
              key: sp,
              validFrom: timeframeFromDate,
              validTo: timeframeToDate
            };
          }
          return { key: sp };
        })
      };

      updatePermissions(payload);
    }
  };

  const handleRemoveUser = () => {
    if (selectedEntity?.value && selectedUser?.email) {
      const payload = {
        email: selectedUser?.email,
        entityIds: [selectedEntity.value],
        permissions: []
      };
      updatePermissions(payload);
    }
  };

  return (
    <StyledModal visible onCancel={() => setShowEditModal(false)}>
      <>
        <Header>{fm({ id: 'useradmin.modal.edit.header' })}</Header>
        {selectedUser?.name && <div>{selectedUser.name}</div>}
        {selectedUser?.email && <div>{selectedUser.email}</div>}
        <Content>
          <LeftSection>
            <PermissionSelector />
            {saveButtonPressed && selectedPermissions.length <= 0 && (
              <ErrorNotice text={fm({ id: 'common.required' })} />
            )}
          </LeftSection>
          <RightSection>
            <Subheader>{fm({ id: 'useradmin.modal.invite.vessel.header' })}</Subheader>
            {selectedEntity?.name}
          </RightSection>
        </Content>
        <Footer>
          <SdirButton text={fm({ id: 'common.cancel' })} onClick={() => setShowEditModal(false)} />
          <ActionButtonWrapper>
            <SdirButton
              type="danger"
              text={fm({ id: 'useradmin.modal.remove.label' })}
              onClick={handleRemoveUser}
            />
            <SdirButton
              type="primary"
              disabled={updatePermissionsLoading}
              text={fm({ id: 'common.save' })}
              onClick={handleUpdatePermissions}
            />
          </ActionButtonWrapper>
        </Footer>
      </>
    </StyledModal>
  );
};

const ActionButtonWrapper = styled.div`
  margin-top: 5rem;
  display: flex;
  gap: 3rem;
  align-items: center;
`;

const StyledModal = styled(SdirModal)`
  font-size: 16px;
  width: 70vw;
  max-width: 1000px;
  max-height: 95vh;
`;

export default UserAdministrationEditModal;
