import React, { useState, useMemo } from 'react';
import { SdirButton, SdirCheckbox, SdirInput, SdirModal } from '@sdir/sds';
import styled from 'styled-components';
import { ErrorNotice } from '@sdir/blueprint.et/lib/components';
import { GetCheckpointOptionsResultV2 } from '@sdir/httpclient/lib/clients/core/requirement';
import useUserAdministrationModalUtilitites, {
  CheckboxWrapper,
  PermissionEntity,
  Permissions,
  Subheader
} from './useUserAdministrationModalUtilties';

interface Props {
  checkpointOptions?: GetCheckpointOptionsResultV2 | undefined;
  setShowAddModal: React.Dispatch<React.SetStateAction<boolean>>;
  getInvitedUsersAndUsers: () => void;
  vessels: PermissionEntity[];
}

// enum ActivitiesSubjectareas {
//   ALL = 'ALL',
//   LIMITED = 'LIMITED'
// }

const UserAdministrationAddModal: React.FC<Props> = ({
  vessels,
  setShowAddModal,
  getInvitedUsersAndUsers
}) => {
  const [emailInput, setEmailInput] = useState('');
  const [selectedVesselUids, setSelectedVesselUids] = useState<string[]>([]);
  const [selectedPermissions, setSelectedPermissions] = useState<Permissions[]>([]);

  // const [activitiesSubjectareas, setActivitiesSubjectareas] = useState<
  //   ActivitiesSubjectareas.ALL | ActivitiesSubjectareas.LIMITED | undefined
  // >();
  // const [limitedSubjectareas, setLimitedSubjectareas] = useState<string[]>([]);
  const [timeframeActivities, setTimeframeActivities] = useState(false);
  const [inviteButtonPressed, setInviteButtonPressed] = useState(false);
  const [timeframeFromDate, setTimeframeFromDate] = useState<Date | null | undefined>();
  const [timeframeToDate, setTimeframeToDate] = useState<Date | null | undefined>();

  const {
    fm,
    sendInvitation,
    sendInvitationLoading,
    PermissionSelector
  } = useUserAdministrationModalUtilitites(
    selectedPermissions,
    setSelectedPermissions,
    timeframeActivities,
    setTimeframeActivities,
    timeframeFromDate,
    setTimeframeFromDate,
    timeframeToDate,
    setTimeframeToDate,
    getInvitedUsersAndUsers,
    setShowAddModal
  );

  const handleVesselCheckboxClicked = vesselUid => {
    if (selectedVesselUids.includes(vesselUid)) {
      setSelectedVesselUids(selectedVesselUids.filter(uids => uids !== vesselUid));
    } else {
      setSelectedVesselUids([...selectedVesselUids, vesselUid]);
    }
  };

  const handleAddRemoveAll = () => {
    if (selectedVesselUids.length === vessels.length) {
      setSelectedVesselUids([]);
    } else {
      setSelectedVesselUids(vessels.map(v => v.value));
    }
  };

  const validEmail = useMemo(() => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(emailInput);
  }, [emailInput]);

  const handleSendInvite = () => {
    setInviteButtonPressed(true);
    if (emailInput && validEmail && selectedVesselUids.length && selectedPermissions.length) {
      const payload = {
        email: emailInput,
        entityIds: selectedVesselUids,
        permissions: selectedPermissions.map(sp => {
          if (
            sp === Permissions.ReadAccessToActivity ||
            sp === Permissions.UpdateAccessToActivity
          ) {
            return {
              key: sp,
              validFrom: timeframeFromDate,
              validTo: timeframeToDate
            };
          }
          return { key: sp };
        })
      };

      sendInvitation(payload);
    }
  };

  return (
    <StyledModal visible onCancel={() => setShowAddModal(false)}>
      <>
        <Header>{fm({ id: 'useradmin.modal.invite.header' })}</Header>
        <InputWrapper>
          <SdirInput
            placeholder={fm({ id: 'useradmin.modal.invite.email' })}
            value={emailInput}
            onChange={e => {
              setEmailInput(e.currentTarget.value);
            }}
          />
          {inviteButtonPressed && !emailInput && (
            <ErrorNotice text={fm({ id: 'common.required' })} />
          )}
          {emailInput && !validEmail && <ErrorNotice text={fm({ id: 'common.error.email' })} />}
        </InputWrapper>
        <Content>
          <LeftSection>
            <PermissionSelector />
            {inviteButtonPressed && selectedPermissions.length <= 0 && (
              <ErrorNotice text={fm({ id: 'common.required' })} />
            )}
          </LeftSection>
          <RightSection>
            <Subheader>{fm({ id: 'useradmin.modal.invite.vessel.header' })}</Subheader>
            <CheckboxWrapper>
              {vessels.length > 0 && (
                <SdirCheckbox
                  title={
                    selectedVesselUids.length === vessels.length
                      ? fm({ id: 'useradmin.modal.invite.vessels.selectnone' })
                      : fm({ id: 'useradmin.modal.invite.vessels.selectall' })
                  }
                  onClick={() => handleAddRemoveAll()}
                  checked={selectedVesselUids.length === vessels.length}
                />
              )}
              {vessels.map(vessel => (
                <SdirCheckbox
                  key={vessel.value}
                  title={vessel.name}
                  onClick={() => handleVesselCheckboxClicked(vessel.value)}
                  checked={selectedVesselUids.includes(vessel.value)}
                />
              ))}
            </CheckboxWrapper>
            {inviteButtonPressed && selectedVesselUids.length <= 0 && (
              <ErrorNotice text={fm({ id: 'common.required' })} />
            )}
          </RightSection>
        </Content>
        <Footer>
          <SdirButton text={fm({ id: 'common.cancel' })} onClick={() => setShowAddModal(false)} />
          <SdirButton
            type="primary"
            disabled={sendInvitationLoading}
            text={fm({ id: 'useradmin.modal.invite.header' })}
            onClick={handleSendInvite}
          />
        </Footer>
      </>
    </StyledModal>
  );
};

export const Content = styled.div`
  margin: 5rem 0;
  display: flex;
  gap: 2rem;
  min-height: 40vh;
  max-height: 55vh;
  overflow-y: auto;
`;

export const Footer = styled.div`
  margin-top: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

// const SelectWrapper = styled.div`
//   width: 30rem;
// `;

const InputWrapper = styled.div`
  width: 50rem;
`;

export const LeftSection = styled.div`
  width: 50%;
`;

export const RightSection = styled.div`
  width: 50%;
`;

export const Header = styled.h2`
  margin-top: 0;
`;

export const CustomContainer = styled.div`
  display: flex;
  flex-direction: column;

  [data-id='base-item'] {
    padding-bottom: 1.5rem;
  }
`;

const StyledModal = styled(SdirModal)`
  font-size: 16px;
  width: 50vw;
  max-width: 1000px;
  max-height: 95vh;
`;

export default UserAdministrationAddModal;
